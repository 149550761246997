'use strict';

const Swiper = require('./thirdParty/swiper');

if ($('.swiper_hero .swiper-slide').length > 1) {
    const swiper_hero_loop = new Swiper('.swiper_hero', {
        breakpoints: {
            // ブレークポイント
            0: {
                // 画面幅600px以上で適用
                slidesPerView: 1,
            },
            600: {
                // 画面幅769px以上で適用
                slidesPerView: 1,
            },
            1200: {
                // 画面幅1200px以上で適用
                slidesPerView: 1,
            },
        },
        centeredSlides: true,
        loop: true, // ループさせる
        speed: 1500, // 少しゆっくり(デフォルトは300)
        autoplay: {
            // 自動再生
            delay: 3000, // 3.0秒後に次のスライド
        },
        pagination: {
            el: '.swiper-pagination', // ページネーション要素のクラス
            clickable: true, // クリックによるスライド切り替えを有効にする
            type: 'bullets', // 'bullets'（デフォルト） | 'fraction' | 'progressbar'
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
} else {
    const swiper_hero = new Swiper('.swiper_hero', {
        breakpoints: {
            // ブレークポイント
            0: {
                // 画面幅600px以上で適用
                slidesPerView: 1,
            },
            600: {
                // 画面幅769px以上で適用
                slidesPerView: 1,
            },
            1200: {
                // 画面幅1200px以上で適用
                slidesPerView: 1,
            },
        },
        centeredSlides: true,
        loop: false, // ループさせない
        speed: 1500, // 少しゆっくり(デフォルトは300)
        autoplay: {
            // 自動再生
            delay: 3000, // 3.0秒後に次のスライド
        },
        pagination: {
            el: '.swiper-pagination', // ページネーション要素のクラス
            clickable: true, // クリックによるスライド切り替えを有効にする
            type: 'bullets', // 'bullets'（デフォルト） | 'fraction' | 'progressbar'
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
}

if ($('.swiper_featured .swiper-slide').length > 1) {
    const swiper_featured_loop = new Swiper('.swiper_featured', {
        breakpoints: {
            // ブレークポイント
            0: {
                // 画面幅600px以上で適用
                slidesPerView: 1.4,
            },
            769: {
                // 画面幅769px以上で適用
                slidesPerView: 3.4,
            },
            1200: {
                // 画面幅1200px以上で適用
                slidesPerView: 3.6,
                spaceBetween: 16,
            },
        },
        centeredSlides: true,
        loop: true, // ループさせる
        speed: 1500, // 少しゆっくり(デフォルトは300)
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
} else {
    const swiper_featured = new Swiper('.swiper_featured', {
        breakpoints: {
            // ブレークポイント
            0: {
                // 画面幅600px以上で適用
                slidesPerView: 1.4,
            },
            769: {
                // 画面幅769px以上で適用
                slidesPerView: 3.4,
            },
            1200: {
                // 画面幅1200px以上で適用
                slidesPerView: 3.6,
                spaceBetween: 16,
            },
        },
        centeredSlides: true,
        loop: false, // ループさせない
        speed: 1500, // 少しゆっくり(デフォルトは300)
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
}

// PDで高さゼロになるのを防ぐため最小値を指定 (PC利用のみ想定)
const productHeightList = [515];
const isSP = $(window).width() > 0 && $(window).width() < 1200;
if (isSP) {
    productHeightList.pop();
}
// ピックアップと新着商品で最も高い商品に合わせて高さを揃える
$('.homepage.products-list-form .product').each(function (i) {
    productHeightList.push($(this).height());
});
$('.homepage.products-list-form .product').css('height', Math.max.apply(null, productHeightList));
